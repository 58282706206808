import './App.css';
import {Container} from "react-bootstrap";

function App() {
  return (
      <Container>
        <h1>About</h1>
        <p>This website is for calculators and stuff!
          Stay tuned for updates and features as I add
          new calculators and other fun things!</p>
      </Container>
  );
}

export default App;
